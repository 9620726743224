/* eslint-disable @typescript-eslint/no-this-alias */
import { HotelDatepicker } from "HotelDatepicker";

export const datePickerProps = {
  validDaySelector: "datepicker__month-day--valid",
  invalidDaySelector: "datepicker__month-day--invalid",
  tmpInvalidSelector: "datepicker__month-day--tmpinvalid",
  visibleMonthSelector: "datepicker__month-day--visibleMonth",
  disabledDaySelector: "datepicker__month-day--disabled",
  noCheckinSelector: "datepicker__month-day--no-checkin",
  forceTimezoneSelector: "datepicker__month-day--force-timezone",
  offerSelector: "datepicker__month-day--offer",
  todaySelector: "datepicker__month-day--today",
  minStaySelector: "datepicker__month-day--minStay",
  lowPricesSelector: "datepicker__month-day--low-prices",
  dayCheckoutEnabledSelector: "datepicker__month-day--checkout-enabled",
  dayBeforeDisabledSelector: "datepicker__month-day--before-disabled-date",
  checkinOnlySelector: "datepicker__month-day--checkin-only",
  noCheckoutSelector: "datepicker__month-day--no-checkout",
  dayOfWeekDisabledSelector: "datepicker__month-day--day-of-week-disabled",
  dayMilliseconds: 86400000,

  tooltipDisabledSelector: "tooltip-disabled-JS",
  tmpSelector: "datepicker__month-day--tmp",
  dateBase: null,
  minPrice: 100000000,
  maxPrice: 0,
  classNameMinPrice: null,
  classNameMaxPrice: null,
  classNamePrice: null,
  percentageMinPrice: 0,
  percentageMaxPrice: 0,
  stayThroughCounter: 0,
  closedDaysCounter: 7,
  counterClosedDays: 0,
  sabreTimeZone: "CET",
  sabreTimeZoneOffset: -2,
  firstCovertCurrency: true,
  ariaLabels: {
    currentDay: null,
    startDay: null,
    endDay: null,
    inRange: null,
  },
};

const datePicker = {
  props: {
    ...datePickerProps,
  },

  init: function () {
    bcl.u.loadStyleSheet("/etc.clientlibs/barcelo/clientlibs/clientlib-datepicker.css");

    HotelDatepicker.prototype.documentClick = this.documentClick;
    HotelDatepicker.prototype.slideDown = this.slideDown;
    HotelDatepicker.prototype.slideUp = this.slideUp;
    HotelDatepicker.prototype.showMonth = this.showMonth;
    HotelDatepicker.prototype.createDatepickerDomString = this.createDatepickerDomString;
    HotelDatepicker.prototype.createMonthDomString = this.createMonthDomString;
    HotelDatepicker.prototype.updateSelectableRange = this.updateSelectableRange;
    HotelDatepicker.prototype.dayClicked = this.dayClicked;
    HotelDatepicker.prototype.goToNextMonth = this.goToNextMonth;
    HotelDatepicker.prototype.dynamicDisabledDates = this.dynamicDisabledDates;
    HotelDatepicker.prototype.hasPrice = this.hasPrice;
    HotelDatepicker.prototype.accomplishesCampaignConditions = this.accomplishesCampaignConditions;
    HotelDatepicker.prototype.updateMinStayInfo = this.updateMinStayInfo;
    HotelDatepicker.prototype.setDayClasses = this.setDayClasses;
    this.initializeInBclObject();
  },
  initializeInBclObject: function () {
    bcl = {
      ...bcl,
      s: {
        ...bcl.s,
        datePicker: this,
      },
    };
  },
  showMonth: function (date, month, notCallback) {
    // Show month table and create the necessary HTML code
    date.setHours(12, 0, 0);
    date.setMilliseconds(0);
    const name = this.getMonthName(date.getMonth());
    const monthDom = this.getMonthDom(month);
    const monthName = monthDom.getElementsByClassName("datepicker__month-name")[0];
    const monthBody = monthDom.getElementsByTagName("tbody")[0];

    // Month caption
    monthName.textContent = name + " " + (document.querySelector("html")?.lang?.replace(/-[A-Z]{2}$/, "") == "ar" ? date.toLocaleDateString("ar-EG", { year: "numeric" }) : date.getFullYear());

    // Remove child elements before to insert the new month
    this.emptyElement(monthBody);

    // Append the month
    monthBody.insertAdjacentHTML("beforeend", this.createMonthDomString(date));

    // Check day dates
    this.updateSelectableRange();

    // Store current month dates
    this["month" + month] = date;

    const _hotelDatePicker = bcl.c.fastbooking.commonProps.calendar.obj;

    if (!notCallback && _hotelDatePicker.callbackShowMonth) {
      _hotelDatePicker.callbackShowMonth(date.toString(), parseInt(month));
    }
  },
  disabledDates: function (hotelDatepicker, resetDate, data, dates) {
    const disabledDates = [],
      prices = data.prices;

    if ((!prices || prices.length == 0) && !bcl.c.fastbooking.commonProps?.calendar?.destinationWasClicked) {
      return;
    }

    const date = bcl.u.getNormalizedDate(dates.startDate),
      endDate = bcl.u.getNormalizedDate(dates.endDate),
      objDisabledDates = {};
    const currentDate = bcl.u.getNormalizedDate();
    while (date <= endDate) {
      if (bcl.c.fastbooking.commonProps.calendar?.destinationWasClicked) {
        if (currentDate > date) {
          objDisabledDates[date.toISOString().split("T")[0]] = true;
        }
      } else {
        objDisabledDates[date.toISOString().split("T")[0]] = true;
      }
      date.setDate(date.getDate() + 1);
    }
    if (!bcl.c.fastbooking.commonProps.calendar?.destinationWasClicked) {
      let firstDate = true;
      for (let i = 0; i < prices.length; i++) {
        // Timezone fix
        if (firstDate) {
          firstDate = false;
          if (bcl.s.datePicker.timezoneCondition()) {
            delete objDisabledDates[prices[i].day];
            continue;
          }
        }

        const price = prices[i].Amount || prices[i].AmountWithTaxesFees;
        if (objDisabledDates[prices[i].day] && !prices[i].closed && price !== 0) {
          delete objDisabledDates[prices[i].day];
        }
      }
    }
    for (const key in objDisabledDates) {
      if (!hotelDatepicker.disabledDates.includes(key)) {
        disabledDates.push(key);
      }
    }

    Array.prototype.push.apply(hotelDatepicker.disabledDates, disabledDates);

    hotelDatepicker.parseDisabledDates();

    if (resetDate === 3) {
      hotelDatepicker.showMonth(hotelDatepicker.month1, 1, true);
      hotelDatepicker.showMonth(hotelDatepicker.month2, 2, true);
      hotelDatepicker.showSelectedDays();
      hotelDatepicker.checkSelection();
    } else if (resetDate !== null) {
      hotelDatepicker.showMonth(hotelDatepicker["month" + resetDate], resetDate, true);
      hotelDatepicker.showSelectedDays();
      hotelDatepicker.checkSelection();
    }
  },

  noArrivalDates: function (hotelDatepicker, data, dates) {
    hotelDatepicker.noCheckInDates = [];

    const startDate = bcl.u.getNormalizedDate(dates.startDate);
    const endDate = bcl.u.getNormalizedDate(dates.endDate);
    if (data) {
      (data.prices || []).forEach(function (price) {
        if (!price.day) {
          return;
        }
        const currentPriceDate = bcl.u.getNormalizedDate(new Date(price.day));
        if (price.noArrival && currentPriceDate >= startDate && currentPriceDate <= endDate) {
          hotelDatepicker.noCheckInDates.push(price.day);
        }
      });
    }
  },

  setDates: function ($container, data, dataOffer, newDate, isCorrelatedHotelPrice) {
    bcl.s.datePicker.updateDateBase($container);
    const prices = data.prices;

    if (!prices) {
      bcl.s.datePicker.updatedHotelClosingTooltips($container);
      return;
    }

    if (bcl.u.mobile.isMobile() || newDate || datePickerProps.countActions === 2 || datePickerProps.countActions === 0) {
      datePickerProps.countActions = 2;
      datePickerProps.minPrice = 100000000;
      datePickerProps.maxPrice = 0;
    }

    if (!newDate) {
      datePickerProps.countActions--;
    }

    datePickerProps.lastPriceNodes = datePickerProps.priceNodes;
    datePickerProps.priceNodes = [];

    if (dataOffer) {
      bcl.s.datePicker.initDataOffer(dataOffer);
    } else {
      datePickerProps.dataOffer = null;
    }

    let updateLimit = false;

    for (let i = 0; i < prices.length; i++) {
      for (let j = i; j < i + datePickerProps.closedDaysCounter; j++) {
        if (prices[j] && prices[j].closed === true) {
          datePickerProps.counterClosedDays++;
        } else if (prices[j] && prices[j].closed === false) {
          datePickerProps.counterClosedDays = 0;
        }
      }
      const retValue = bcl.s.datePicker.setDate($container, prices[i], data, datePickerProps.counterClosedDays < datePickerProps.closedDaysCounter, isCorrelatedHotelPrice);

      if (!updateLimit) {
        updateLimit = retValue;
      }
    }

    bcl.s.tooltip.init($container);

    bcl.s.datePicker.updatedNodes(datePickerProps.priceNodes);

    if (updateLimit && datePickerProps.lastPriceNodes && datePickerProps.lastPriceNodes.length) {
      bcl.s.datePicker.updatedNodes(datePickerProps.lastPriceNodes);
    }
  },

  updatedHotelClosingTooltips: function ($container) {
    if (!bcl.c.fastbooking.commonProps.calendar.dataMessage) {
      return;
    }

    const hotelclosing = bcl.c.fastbooking.commonProps.calendar.dataMessage.hotelclosing,
      hotelopening = bcl.c.fastbooking.commonProps.calendar.dataMessage.hotelopening;
    let startDate = bcl.s.datePicker.createDate(bcl.c.fastbooking.calendar.parseDate(bcl.c.fastbooking.commonProps.calendar.obj.month1)),
      endDate = bcl.s.datePicker.createDate(bcl.c.fastbooking.calendar.parseDate(bcl.c.fastbooking.commonProps.calendar.obj.month2));

    endDate.setMonth(endDate.getMonth() + 1);

    if (!startDate || !endDate || !hotelclosing || !hotelopening || !(startDate >= hotelclosing && startDate < hotelopening)) {
      return;
    }

    if (endDate >= hotelopening) {
      endDate = hotelopening;
    }

    let date = bcl.u.getNormalizedDate();

    date = bcl.s.datePicker.createDate(bcl.c.fastbooking.calendar.parseDate(date));

    if (startDate < date) {
      startDate = date;
    }

    const diffTime = Math.abs(startDate - endDate),
      diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    for (let i = 0; i < diffDays; i++) {
      const $item = $container.querySelector("td." + datePickerProps.visibleMonthSelector + '[time="' + startDate.getTime() + '"]');

      $item && bcl.s.datePicker.updatedTooltip($item, bcl.c.fastbooking.calendar.props.tooltipSelector);

      startDate.setDate(startDate.getDate() + 1);
    }

    bcl.s.tooltip.init($container);
  },

  updatedTooltips: function ($container, $items, className) {
    bcl.u.forEach($items, function ($item) {
      bcl.s.datePicker.updatedTooltip($item, className);
    });

    bcl.s.tooltip.init($container);
  },

  updatedTooltip: function ($item, className, valid) {
    if (typeof valid !== "undefined" && !valid) {
      this.setDayClasses($item, false);
      bcl.u.addClass($item, datePickerProps.tmpSelector);
    }

    if (!$item.dataset.tooltip) {
      bcl.u.addClass($item, "tooltip-JS");
      let event = "click";

      if (!bcl.u.mobile.isMobile() && $item && ($item.classList?.contains(datePickerProps.disabledDaySelector) || $item.classList?.contains(datePickerProps.minStaySelector))) {
        event = "mouseover";
      }
      $item.dataset.event = event;
      $item.dataset.closeonclick = "true";

      $item.dataset.tooltip = className;
      $item.dataset.width = "center";
    }
  },

  updatedNodes: function (priceNodes) {
    if (priceNodes) {
      let min, max;
      const priceNodesArray = Array.from(priceNodes);
      const prices = priceNodesArray.filter((el) => el.price > 0)?.map((el) => el.price);
      if (prices?.length > 0) {
        min = Math.min(...prices);
        max = Math.max(...prices);
        const minPrice = min * (1 + datePickerProps.percentageMinPrice / 100),
          maxPrice = max * (1 - datePickerProps.percentageMaxPrice / 100);
        priceNodesArray.forEach((item) => {
          const $span = item.$node.children[0];
          if ($span) {
            if (item.price <= minPrice) {
              bcl.s.datePicker.updateClass($span, datePickerProps.classNameMinPrice);
            } else if (item.price >= maxPrice) {
              bcl.s.datePicker.updateClass($span, datePickerProps.classNameMaxPrice);
            } else {
              bcl.s.datePicker.updateClass($span, datePickerProps.classNamePrice);
            }
          }
        });
      }
    }
  },

  updateClass: function ($span, addClass) {
    bcl.u.removeClass($span, datePickerProps.classNameMinPrice);
    bcl.u.removeClass($span, datePickerProps.classNameMaxPrice);
    bcl.u.removeClass($span, datePickerProps.classNamePrice);

    bcl.u.addClass($span, addClass);
  },

  dynamicSort: function () {
    return function (a, b) {
      const getA = a.price,
        getB = b.price;

      return getA < getB ? -1 : getA > getB ? 1 : 0;
    };
  },

  clearPricesDates: function ($container) {
    const $items = $container.querySelectorAll("td.datepicker__month-day");

    bcl.u.forEach($items, function ($item) {
      if ($item.children.length) {
        $item.removeChild($item.children[0]);
      }

      bcl.u.removeClass($item, datePickerProps.lowPricesSelector);
      bcl.u.removeClass($item, datePickerProps.offerSelector);
    });

    datePickerProps.priceNodes = [];
  },

  initDataOffer: function (dataOffer) {
    datePickerProps.dataOffer = {
      startDate: bcl.u.getNormalizedDate(dataOffer.booking_start),
      endDate: bcl.u.getNormalizedDate(dataOffer.booking_end),
      data: dataOffer,
    };
  },

  createDate: function (day) {
    const date = bcl.u.getNormalizedDate(day + datePickerProps.dateBase);
    return date;
  },

  setDate: function ($container, item, data, closedOnlyFewDay, isCorrelatedHotelPrice) {
    const date = bcl.s.datePicker.createDate(item.day);
    if (!date || date < datePickerProps.date) {
      return;
    }

    const $item = $container.querySelector("td." + datePickerProps.visibleMonthSelector + '[time="' + date.getTime() + '"]');

    if (!$item) {
      return;
    }

    if ($item.children.length > 0) {
      return;
    }

    if (data.promoStart && data.promoEnd) {
      const promoStart = bcl.u.getNormalizedDate(data.promoStart),
        promoEnd = bcl.u.getNormalizedDate(data.promoEnd);

      if (promoStart.getTime() <= date.getTime() && date.getTime() <= promoEnd.getTime()) {
        bcl.u.addClass($item, datePickerProps.offerSelector);
        bcl.u.removeClass(bcl.c.fastbooking.calendar.props.$offerMessage, "hidden");
      }
    }

    const price = Math.ceil(item.Amount || item.AmountWithTaxesFees);

    if (item.closed) {
      if (closedOnlyFewDay) {
        $item.dataset.tooltipMessage = "hotelClosingSales";
        bcl.s.datePicker.updatedTooltip($item, bcl.c.fastbooking.calendar.props.tooltipSelector);
        bcl.u.removeClass($item, datePickerProps.tooltipDisabledSelector);
      } else {
        $item.dataset.tooltipMessage = "hotelClosingReform";
        bcl.s.datePicker.updatedTooltip($item, bcl.c.fastbooking.calendar.props.tooltipSelector);
        bcl.u.removeClass($item, datePickerProps.tooltipDisabledSelector);
      }
    } else if (price == 0 || price < -1) {
      //price == -1 --> Cuban hotel
      $item.dataset.tooltipMessage = "hotelClosingAvailability";
      bcl.s.datePicker.updatedTooltip($item, bcl.c.fastbooking.calendar.props.tooltipSelector);
      bcl.u.removeClass($item, datePickerProps.tooltipDisabledSelector);
      bcl.c.fastbooking.calendar.props.showOnlyCampaignPrices && bcl.c.fastbooking.isCampaignGroup?.() && bcl.u.addClass($item, datePickerProps.invalidDaySelector);
    } else if (isCorrelatedHotelPrice) {
      const hasPrice = price > 0;
      $item.dataset.tooltipMessage = "hotelRelatedPrice";
      bcl.s.datePicker.updatedTooltip($item, bcl.c.fastbooking.calendar.props.tooltipSelector, hasPrice);
    } else if (price > 0 && bcl.c.fastbooking.isCampaignGroup?.()) {
      bcl.u.removeClass($item, datePickerProps.tmpSelector);
      this.setDayClasses($item, true);
      bcl.u.removeClass($item, datePickerProps.disabledDaySelector);
    } else {
      bcl.u.removeClass($item, bcl.c.fastbooking.calendar.props.tooltipSelector);
    }

    // Timezone fix
    if (bcl.u.containsClass($item, datePickerProps.todaySelector)) {
      if (bcl.u.containsClass($item, datePickerProps.forceTimezoneSelector) && !bcl.u.mobile.isMobile()) {
        $item.dataset.tooltipMessage = "hotelTimezone";
        bcl.s.datePicker.updatedTooltip($item, bcl.c.fastbooking.calendar.props.tooltipSelector, true);
        bcl.u.removeClass($item, datePickerProps.tooltipDisabledSelector);
      } else {
        bcl.u.removeClass($item, bcl.c.fastbooking.calendar.props.tooltipSelector);
      }
    }

    if ($item.previousSibling && bcl.u.containsClass($item.previousSibling, datePickerProps.forceTimezoneSelector)) {
      if (bcl.u.containsClass($item.previousSibling, datePickerProps.todaySelector) && !bcl.u.mobile.isMobile()) {
        $item.previousSibling.dataset.tooltipMessage = "hotelTimezone";
        this.updatedTooltip($item.previousSibling, bcl.c.fastbooking.calendar.props.tooltipSelector, true);
        bcl.u.removeClass($item.previousSibling, datePickerProps.tooltipDisabledSelector);
      } else {
        bcl.u.removeClass($item.previousSibling, bcl.c.fastbooking.calendar.props.tooltipSelector);
      }
    }

    const value = bcl.s.currency.formatPrice(price);
    if (bcl.c.fastbooking.commonProps.fastbooking.enabledRounded) {
      $item.innerHTML += '<span class="price-day-calendar price-day-calendar–dot"></span>';
    } else if (price > 0) {
      if (!bcl.c.fastbooking.commonProps.fastbooking.disabledPrices) {
        let marketPrice = price;
        if (document.body.dataset.currency !== data.currency) {
          const rate = data.relativeRates.ratesMap[document.body.dataset.currency].rate;
          marketPrice = Math.ceil(marketPrice * rate);
        }
        $item.innerHTML =
          '<span class="price-day-calendar"><span class="c-price__value c-price__value-JS" data-market-price="' + marketPrice + '" data-relativeprice="' + price + '">' + value + '</span><span class="c-price__currency"></span></span>' + $item.innerHTML;
      } else if (bcl.c.fastbooking.commonProps.fastbooking.enabledDots) {
        $item.innerHTML += '<span class="price-day-calendar price-day-calendar–dot"></span>';
      }
    }

    if (item.minStay && item.minStay != 0) {
      $item.dataset.minstay = item.minStay;
    }

    if (item.minStayThrough && item.minStayThrough != 0) {
      $item.dataset.minStayThrough = item.minStayThrough;
    }

    bcl.u.addClass($item, "mod--loaded");

    let updateLimit = false;

    if (datePickerProps.minPrice >= price && price > 0) {
      datePickerProps.minPrice = price;
      updateLimit = true;
    }

    if (datePickerProps.maxPrice <= price) {
      datePickerProps.maxPrice = price;
      updateLimit = true;
    }

    datePickerProps.priceNodes.push({
      price: price,
      $node: $item,
    });

    return updateLimit;
  },
  updateDateBase: function ($container) {
    const td = $container.querySelector("tbody > tr > td"),
      date = bcl.u.getNormalizedDate(parseInt(td.getAttribute("time")));

    datePickerProps.date = date;
    datePickerProps.dateBase = "T" + date.toISOString().split("T")[1];
    datePickerProps.dateBaseGetTimezoneOffset = date.getTimezoneOffset();
  },

  documentClick: function (evt) {
    let $node = null;
    if (this.parent.contains(evt.target) || evt.target == this.input) {
      if (evt.target.tagName.toLowerCase() === "td" && bcl.u.containsClass(evt.target, "datepicker__month-day") && !bcl.u.containsClass(evt.target, datePickerProps.disabledDaySelector)) {
        $node = evt.target;
      } else if (bcl.u.containsClass(evt.target.parentNode, "price-day-calendar")) {
        $node = evt.target.parentNode.parentNode;
      } else if (bcl.u.containsClass(evt.target.parentNode, "datepicker__month-day")) {
        $node = evt.target.parentNode;
      }
    }

    if ($node && !bcl.u.containsClass(bcl.c.fastbooking.calendar.props.$wrapper, bcl.c.fastbooking.calendar.props.classLoading)) {
      // Check if the click was on a calendar day
      this.dayClicked($node);
      bcl.s.datePicker.checkRange(this);
    }

    if (evt.target.classList.contains("stopProp-JS")) {
      evt.stopPropagation();
      evt.preventDefault();
    }
  },

  checkRange: function (datePicker) {
    if (datePicker.callbackSetRangeDate && !isNaN(datePicker.start) && !isNaN(datePicker.end)) {
      datePicker.callbackSetRangeDate(datePicker.start, datePicker.end);
    }
  },

  slideDown: function (element) {
    // Slide down an element
    element.style.display = "";

    element.addEventListener("transitionend", function () {
      element.style.height = element.style.transition = element.style.display = "";
    });
  },

  slideUp: function (element) {
    // Slide up an element
    element.addEventListener("transitionend", function () {
      element.style.display = "none";
    });
  },
  createMonthDomString: function (_date) {
    const this$1 = this;

    const days = [];
    let html = "";
    let valid;
    _date.setDate(1);

    let dayOfWeek = _date.getDay();
    const currentMonth = _date.getMonth();

    if (dayOfWeek === 0 && this.startOfWeek === "monday") {
      // Add one week
      dayOfWeek = 7;
    }

    // If the first day is in the middle of the week, push also
    // the first days of the week (the days before our first day).
    // We need a complete week row.
    // Obviously, these days are part of the previous month.
    if (dayOfWeek > 0) {
      for (let i = dayOfWeek; i > 0; i--) {
        const _day = bcl.u.getNormalizedDate(_date.getTime() - 86400000 * i);

        // Check if the day is valid. And pass this property to the days object
        valid = this$1.isValidDate(_day.getTime());

        if ((this$1.startDate && this$1.compareDay(_day, this$1.startDate) < 0) || (this$1.endDate && this$1.compareDay(_day, this$1.endDate) > 0)) {
          valid = false;
        }

        // We pass the type property to know if the day is part of the
        // previous month. We already know that it is true.
        days.push({
          date: _day,
          type: "lastMonth",
          day: _day.getDate(),
          time: _day.getTime(),
          valid: valid,
        });
      }
    }

    // Push 40 days. Each month table needs the days of the month plus
    // the remaining days (of the week row) before the first day of the month
    // and after the last day of the month. (PS. They will be hidden)
    // 40 days are enough to cover all the possibilities.
    for (let i$1 = 0; i$1 < 40; i$1++) {
      const _day$1 = this$1.addDays(_date, i$1);

      // Check if the day is valid. And pass this property to the days object
      valid = this$1.isValidDate(_day$1.getTime());

      if ((this$1.startDate && this$1.compareDay(_day$1, this$1.startDate) < 0) || (this$1.endDate && this$1.compareDay(_day$1, this$1.endDate) > 0)) {
        valid = false;
      }

      // We pass the type property to know if the day is part of the
      // current month or part of the next month
      days.push({
        date: _day$1,
        type: _day$1.getMonth() === currentMonth ? "visibleMonth" : "nextMonth",
        day: _day$1.getDate(),
        time: _day$1.getTime(),
        valid: valid,
      });
    }

    // Create the week rows.
    for (let week = 0; week < 6; week++) {
      // Iterate the days object week by week.
      // If the last day is part of the next month, stop the loop.
      if (days[week * 7].type === "nextMonth") {
        break;
      }

      html += '<tr class="datepicker__week-row">';

      // Create the days of a week, one by one
      for (let i$2 = 0; i$2 < 7; i$2++) {
        let _day$2 = this$1.startOfWeek === "monday" ? i$2 + 1 : i$2;
        _day$2 = days[week * 7 + _day$2];
        const isToday = this$1.getDateString(_day$2.time) === this$1.getDateString(bcl.u.getNormalizedDate());
        const isStartDate = this$1.getDateString(_day$2.time) === this$1.getDateString(this$1.startDate);
        let isDisabled = false;
        let isNoCheckIn = false;
        let isNoCheckOut = false;
        let isDayOfWeekDisabled = false;
        let isFirstEnabledDate = false;

        // Day between disabled dates and the last day
        // before the disabled date
        let isDayBeforeDisabledDate = false;

        // Check if the day is one of the days passed in the
        // (optional) disabledDates option. And set valid to
        // false in this case.
        //
        // Also, check if the checkin or checkout is disabled
        if (_day$2.valid || _day$2.type === "visibleMonth") {
          const dateString = this$1.getDateString(_day$2.time, "YYYY-MM-DD");
          if (this$1.disabledDates.length > 0) {
            // Check if this day is between two disabled dates
            // and disable it if there are not enough days
            // available to select a valid range
            const limit = this$1.getClosestDates(_day$2.date);

            if (limit[0] && limit[1]) {
              if (this$1.compareDay(_day$2.date, limit[0]) && this$1.countDays(limit[0], limit[1]) - 2 > 0) {
                const daysBeforeNextDisabledDate = this$1.countDays(limit[1], _day$2.date);
                const daysAfterPrevDisabledDate = this$1.countDays(_day$2.date, limit[0]);

                if (this$1.selectForward && daysBeforeNextDisabledDate < this$1.minDays) {
                  _day$2.valid = false;
                } else if (!this$1.selectForward && daysBeforeNextDisabledDate < this$1.minDays && daysAfterPrevDisabledDate < this$1.minDays) {
                  _day$2.valid = false;
                }

                if (!_day$2.valid && this$1.enableCheckout && daysBeforeNextDisabledDate === 2) {
                  isDayBeforeDisabledDate = true;
                }
              }
            }

            // First day after a disabled day
            if (limit[0] && this$1.countDays(limit[0], _day$2.date) === 3) {
              isFirstEnabledDate = true;
            }

            if (this$1.disabledDates.indexOf(dateString) > -1) {
              _day$2.valid = false;
              isDisabled = true;

              this$1.isFirstDisabledDate++;
            } else {
              this$1.isFirstDisabledDate = 0;
            }
          }

          if (this$1.disabledDaysOfWeek.length > 0) {
            if (this$1.disabledDaysOfWeek.indexOf(window.fecha.format(_day$2.time, "dddd")) > -1) {
              _day$2.valid = false;
              isDayOfWeekDisabled = true;
            }
          }

          if (this$1.noCheckInDates.length > 0) {
            if (this$1.noCheckInDates.indexOf(dateString) > -1) {
              isNoCheckIn = true;
              isFirstEnabledDate = false;
            }
          }

          if (this$1.noCheckOutDates.length > 0) {
            if (this$1.noCheckOutDates.indexOf(dateString) > -1) {
              isNoCheckOut = true;
            }
          }
        }

        // Timezone Fix
        let forceTimezone = false;
        if (isToday && bcl.s.datePicker.timezoneCondition()) {
          isDisabled = false;
          _day$2.valid = true;
          forceTimezone = true;
        }

        const classes = [
          "datepicker__month-day--" + _day$2.type,
          "datepicker__month-day--" + (_day$2.valid ? "valid" : "invalid"),
          isToday ? datePickerProps.todaySelector : "",
          isDisabled ? datePickerProps.disabledDaySelector : "",
          isDisabled && this$1.enableCheckout && this$1.isFirstDisabledDate === 1 ? datePickerProps.dayCheckoutEnabledSelector : "",
          isDayBeforeDisabledDate ? datePickerProps.dayBeforeDisabledSelector : "",
          isStartDate || isFirstEnabledDate ? datePickerProps.checkinOnlySelector : "",
          isNoCheckIn ? datePickerProps.noCheckinSelector : "",
          isNoCheckOut ? datePickerProps.noCheckoutSelector : "",
          isDayOfWeekDisabled ? datePickerProps.dayOfWeekDisabledSelector : "",
          forceTimezone ? datePickerProps.forceTimezoneSelector : "",
          this.start && _day$2.time < this.start ? datePickerProps.tmpSelector : "",
        ];

        // Add a title for those days where the checkin or checkout is disabled
        let title = "";

        if (isNoCheckIn) {
          title = this$1.i18n["checkin-disabled"];
        }

        if (isNoCheckOut) {
          if (title) {
            title += ". ";
          }

          title += this$1.i18n["checkout-disabled"];
        }

        // Each day has the "time" attribute (timestamp) and an appropriate class
        const dayAttributes = {
          time: _day$2.time,
          class: classes.join(" "),
        };

        // Add title attribute if available
        if (title) {
          dayAttributes.title = title;
        }
        let ariaLabelByText = "Current Day";
        if (isToday) {
          if (datePickerProps?.ariaLabels?.currentDay) {
            ariaLabelByText = datePickerProps.ariaLabels.currentDay;
          } else {
            const currentAriaLabel = document.querySelector(".c-fastbooking__calendar-wrapper")?.dataset?.ariaCurrent;
            if (currentAriaLabel) {
              ariaLabelByText = currentAriaLabel;
            }
          }
          dayAttributes["aria-labelledby"] = "currentday";
        }
        // Create the day HTML
        html += `<td class="datepicker__month-day ${dayAttributes.class}" ${this$1.printAttributes(dayAttributes)}>
          ${isToday ? `<span class="sr-only visuallyhidden" id="currentday">${ariaLabelByText}</span>` : ""}
          ${this.i18n["day-numbers"] ? this.i18n["day-numbers"][_day$2.day - 1] : _day$2.day}</td>
        `;
      }

      html += "</tr>";
    }

    return html;
  },

  timezoneCondition: function () {
    const date = new Date();
    const nextDate = new Date();
    nextDate.setDate(date.getDate() + 1);

    if (bcl.u.browser.IE() || bcl.u.browser.isEdge()) {
      return date.getHours() + date.getTimezoneOffset() / 60 + datePickerProps.sabreTimeZoneOffset >= 24;
    } else {
      const sabreDate = new Date(date.toLocaleString("en-US", { timeZone: datePickerProps.sabreTimeZone }));
      return nextDate.getDate() == sabreDate.getDate();
    }
  },

  checkMinStayThrough: function ($day, numDays) {
    numDays--;
    let minStayThroughPassed = false;

    if (datePickerProps.stayThroughCounter <= 0) {
      minStayThroughPassed = true;
    }

    if (datePickerProps.stayThroughCounter > 0) {
      datePickerProps.stayThroughCounter--;
    }

    if ($day.dataset.minStayThrough) {
      const minStayThrough = parseInt($day.dataset.minStayThrough);
      const diffDays = minStayThrough - numDays - 1;
      datePickerProps.stayThroughCounter = datePickerProps.stayThroughCounter >= diffDays ? datePickerProps.stayThroughCounter : diffDays;
    }

    return minStayThroughPassed;
  },
  setDayClasses: function ($day, isValid) {
    if (!$day) {
      return;
    }
    if (isValid) {
      bcl.u.addClass($day, datePickerProps.validDaySelector);
      bcl.u.removeClass($day, datePickerProps.invalidDaySelector);
    } else {
      bcl.u.addClass($day, datePickerProps.invalidDaySelector);
      bcl.u.removeClass($day, datePickerProps.validDaySelector);
    }
  },
  updateSelectableRange: function () {
    const this$1 = this;

    const days = this.datepicker.getElementsByTagName("td");
    const isSelecting = this.start && !this.end;
    let hasTooltip = false;

    // Add needed classes
    for (let i = 0; i < days.length; i++) {
      const day = days[i];
      if (this$1.hasClass(day, datePickerProps.invalidDaySelector) && this$1.hasClass(day, datePickerProps.tmpSelector)) {
        this$1.removeClass(day, datePickerProps.tmpSelector);
        if (this$1.hasClass(day, datePickerProps.tmpInvalidSelector)) {
          this$1.removeClass(day, datePickerProps.tmpInvalidSelector);
        } else {
          this.setDayClasses(day, true);
        }
        if (this$1.hasClass(day, "tooltip-tempEnabled-JS")) {
          this$1.addClass(day, datePickerProps.tooltipDisabledSelector);
        }
      }

      this$1.removeClass(day, datePickerProps.minStaySelector);
      this$1.removeClass(day, bcl.c.fastbooking.calendar.props.tooltipSelector);
      this$1.removeClass(day, "tooltip-tempEnabled-JS");

      // Update day classes during the date range selection
      if (isSelecting) {
        if (
          this$1.hasClass(day, datePickerProps.visibleMonthSelector) &&
          (this$1.hasClass(day, datePickerProps.validDaySelector) || this$1.hasClass(day, datePickerProps.disabledDaySelector) || this$1.hasClass(day, datePickerProps.dayBeforeDisabledSelector))
        ) {
          const time = parseInt(day.getAttribute("time"), 10);
          if (this$1.isValidDate(time) && this$1.accomplishesCampaignConditions(day)) {
            this.setDayClasses(day, true);
            this$1.addClass(day, datePickerProps.tmpSelector);
            this$1.addClass(day, datePickerProps.tooltipDisabledSelector);
            this$1.removeClass(day, datePickerProps.disabledDaySelector);
            const isAfterStart = this.countDays(time, this.start) > 0;
            if (this.start && !this.end && isAfterStart && !bcl.s.datePicker.checkMinStayThrough(day, this.countDays(time, this.start))) {
              this$1.updateMinStayInfo(day);
              hasTooltip = true;

              // Timezone Fix
            } else if ((this$1.hasClass(day, datePickerProps.forceTimezoneSelector) && isAfterStart && !bcl.u.mobile.isMobile()) || (day.dataset && day.dataset.tooltipMessage && day.dataset.tooltipMessage === "hotelRelatedPrice")) {
              this$1.removeClass(day, datePickerProps.tooltipDisabledSelector);
              hasTooltip = true;
            }
          } else {
            if (this$1.hasClass(day, datePickerProps.invalidDaySelector)) {
              this$1.addClass(day, datePickerProps.tmpInvalidSelector);
            }
            this.setDayClasses(day, false);
            this$1.addClass(day, datePickerProps.tmpSelector);

            if (this.start && !this.end) {
              if (this.minDays > 0 && this.start < time && this.countDays(time, this.start) > 1 && this.countDays(time, this.start) < this.minDays) {
                this$1.addClass(day, "tooltip-tempEnabled-JS");
                this$1.addClass(day, datePickerProps.minStaySelector);
                this$1.removeClass(day, datePickerProps.tooltipDisabledSelector);

                day.dataset.tooltipMessage = "hotelClosingMinstay";
                bcl.s.datePicker.updatedTooltip(day, bcl.c?.fastbooking.calendar.props.tooltipSelector);

                hasTooltip = true;
              }
            }
          }
        }
        // At the end of the selection, restore the disabled/invalid class for
        // days where the checkout is enabled. We need to check this when the
        // autoclose option is false .the same for the day just before the
        // disabled date
      } else {
        if (
          this$1.hasClass(day, datePickerProps.dayCheckoutEnabledSelector) ||
          this$1.hasClass(day, datePickerProps.dayBeforeDisabledSelector) ||
          (bcl.c.fastbooking.isCampaignGroup?.() && bcl.c.fastbooking.calendar.props.showOnlyCampaignPrices && !this$1.hasClass(day, datePickerProps.offerSelector))
        ) {
          this$1.addClass(day, datePickerProps.tmpSelector);
          this.setDayClasses(day, false);
          setTimeout(
            function () {
              if (day) {
                this$1.removeClass(day, datePickerProps.tooltipDisabledSelector);
              }
            },
            100,
            days,
            i,
          );
          if (!this$1.hasClass(day, datePickerProps.dayBeforeDisabledSelector)) {
            this$1.addClass(day, datePickerProps.disabledDaySelector);
          }
        } else if (this$1.hasClass(day, datePickerProps.invalidDaySelector) && bcl.s.datePicker.hasPrice(day) && !this$1.hasClass(day, datePickerProps.disabledDaySelector)) {
          this$1.addClass(day, datePickerProps.tmpSelector);
          this.setDayClasses(day, true);
        }
      }

      const currentDate = new Date();
      const dateNow = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()).getTime();
      if (dateNow > day.getAttribute("time")) {
        this$1.removeClass(day, datePickerProps.validDaySelector);
        this$1.addClass(day, datePickerProps.invalidDaySelector);
      }
      bcl.s.datePicker.updateAriaLabelBy(day, this.start, this.end);
    }

    if (hasTooltip) {
      bcl.s.tooltip.init(this.datepicker);
    }

    return true;
  },
  updateAriaLabelBy: (day, start, end) => {
    if (day && bcl.u.containsClass(day, datePickerProps.visibleMonthSelector)) {
      const time = parseInt(day.getAttribute("time"), 10);
      if (start && end && time && !isNaN(time)) {
        const isAfterStart = time > start;
        const ifBeforeEnd = time < end;
        if (isAfterStart && ifBeforeEnd && datePickerProps?.ariaLabels?.inRange) {
          bcl.s.datePicker.appendSpanSrOnlyElement(day, datePickerProps.ariaLabels.inRange, "inrange");
          return;
        }
        if (time == start && datePickerProps?.ariaLabels?.startDay) {
          bcl.s.datePicker.appendSpanSrOnlyElement(day, datePickerProps.ariaLabels.startDay, "startday");
          return;
        }
        if (time == end && datePickerProps?.ariaLabels?.endDay) {
          bcl.s.datePicker.appendSpanSrOnlyElement(day, datePickerProps.ariaLabels.endDay, "endday");
          return;
        }
      }
      if (bcl.u.containsClass(day, datePickerProps.todaySelector) && datePickerProps?.ariaLabels?.currentDay) {
        bcl.s.datePicker.appendSpanSrOnlyElement(day, datePickerProps.ariaLabels.currentDay, "currentday");
        return;
      }
      day.querySelector(".sr-only.visuallyhidden")?.remove();
    }
  },
  appendSpanSrOnlyElement: function (day, text, id) {
    let span = day.querySelector(".sr-only.visuallyhidden");
    if (!span) {
      span = document.createElement("span");
      span.className = "sr-only visuallyhidden";
      span.id = id;
      span.innerHTML = text;
      day.setAttribute("aria-labelledby", id);
      day.prepend(span);
    }
  },

  updateMinStayInfo: function (day) {
    const this$1 = this;
    if (this$1.hasClass(day, datePickerProps.invalidDaySelector)) {
      this$1.addClass(day, datePickerProps.tmpInvalidSelector);
    }
    this.setDayClasses(day, false);
    this$1.addClass(day, datePickerProps.tmpSelector);
    this$1.addClass(day, "tooltip-tempEnabled-JS");
    this$1.addClass(day, datePickerProps.minStaySelector);
    this$1.removeClass(day, datePickerProps.tooltipDisabledSelector);

    day.dataset.tooltipMessage = "hotelClosingMinstay";
    bcl.s.datePicker.updatedTooltip(day, bcl.c.fastbooking.calendar.props.tooltipSelector);
  },
  hasPrice: function (day) {
    if (!day) {
      return false;
    }
    return day.querySelector("[data-market-price]")?.dataset.marketPrice || day.querySelector("[data-relativeprice]")?.dataset.relativeprice;
  },
  accomplishesCampaignConditions: function (day) {
    return (
      !bcl.c.fastbooking.isCampaignGroup?.() ||
      (bcl.c.fastbooking.isCampaignGroup?.() &&
        (day.querySelector(bcl.s.currency.props.selectors.priceItem + "[data-market-price]") || day.querySelector(".price-day-calendar.price-day-calendar–dot") || !bcl.c?.fastbooking.calendar.props.showOnlyCampaignPrices))
    );
  },
  createDatepickerDomString: function () {
    const this$1 = this;

    // Generate our datepicker
    let html = '<div id="' + this.getDatepickerId() + '" style="display:none" class="datepicker datepicker--closed">';

    html += '<div class="datepicker__inner">';

    if (this.showTopbar) {
      // Top bar section
      html +=
        '<div class="datepicker__topbar">' +
        '<div class="datepicker__info datepicker__info--selected"><span class="datepicker__info datepicker__info--selected-label">' +
        this.lang("selected") +
        ' </span> <strong class="datepicker__info-text datepicker__info-text--start-day">...</strong>' +
        ' <span class="datepicker__info-text datepicker__info--separator">' +
        this.separator +
        '</span> <strong class="datepicker__info-text datepicker__info-text--end-day">...</strong> <em class="datepicker__info-text datepicker__info-text--selected-days">(<span></span>)</em>' +
        "</div>" +
        '<div class="datepicker__info datepicker__info--feedback"></div>' +
        '<button type="button" id="' +
        this.getCloseButtonId() +
        '" class="datepicker__close-button">' +
        this.lang("button") +
        "</button>" +
        "</div>";
    }

    // Months section
    html += '<div class="datepicker__months">';

    // Print single months
    for (let i = 1; i <= 2; i++) {
      html +=
        '<table id="' +
        this$1.getMonthTableId(i) +
        '" class="datepicker__month datepicker__month--month' +
        i +
        '"><thead><tr class="datepicker__month-caption"><th><span class="datepicker__month-button datepicker__month-button--prev" month="' +
        i +
        '"></span></th><th colspan="5" class="datepicker__month-name"></th><th><span class="datepicker__month-button datepicker__month-button--next" month="' +
        i +
        '"></span></th></tr><tr class="datepicker__week-days">' +
        this$1.getWeekDayNames(i) +
        "</tr></thead><tbody></tbody></table>";
    }

    html += "</div>";

    // Tooltip
    html += '<div style="display:none" id="' + this.getTooltipId() + '" class="datepicker__tooltip"></div>';

    html += "</div>";

    html += "</div>";

    return html;
  },

  dayClicked: function dayClicked(day) {
    if (bcl.c.fastbooking.calendar.roundedCalendarisEnabled()) {
      bcl.c.fastbooking.calendar.showHideSelectDateMsg(false);
      bcl.c.fastbooking.calendar.showHideNumberNights(false);
    }

    if (this.hasClass(day, datePickerProps.invalidDaySelector)) {
      return;
    }

    const isSelectStart = (this.start && this.end) || (!this.start && !this.end);

    // Return early for those days where the checkin or checkout is disabled
    if (isSelectStart) {
      if (this.hasClass(day, datePickerProps.noCheckinSelector)) {
        return;
      }

      if (day.dataset.minstay && day.dataset.minstay != 0) {
        bcl.c.fastbooking.calendar.props.options.minNights = parseInt(day.dataset.minstay);
      } else {
        bcl.c.fastbooking.calendar.props.options.minNights = 1;
      }

      this.minNights = bcl.c.fastbooking.calendar.props.options.minNights;
      this.minDays = this.minNights > 1 ? this.minNights + 1 : 2;

      // Timezone Fix
      if (bcl.u.containsClass(day, datePickerProps.forceTimezoneSelector)) {
        this.timezoneFirstDay = true;
      } else {
        bcl.c.fastbooking.props.redirect = false;
        this.timezoneFirstDay = null;
      }
    } else if (this.start) {
      if (this.hasClass(day, datePickerProps.noCheckoutSelector)) {
        return;
      }
    }

    const time = parseInt(day.getAttribute("time"), 10);
    this.addClass(day, "datepicker__month-day--selected");

    if (isSelectStart) {
      this.start = time;
      this.end = false;
    } else if (this.start) {
      this.end = time;
    }

    // Swap dates if they are inverted
    if (this.start && this.end && this.start > this.end) {
      const tmp = this.end;

      this.end = this.start;
      this.start = tmp;
    }

    this.start = parseInt(this.start, 10);
    this.end = parseInt(this.end, 10);

    // Remove hovering class from every day and hide tooltip
    this.clearHovering();

    // Show hover
    if (this.start && !this.end) {
      // Add hovering class
      this.dayHovering(day);
    }

    // Check day dates
    this.updateSelectableRange();

    // Check the selection
    this.checkSelection();

    // Show selected dates in top bar
    this.showSelectedInfo();

    // Show selected days in the calendar
    this.showSelectedDays();

    // Close the datepicker
    this.autoclose();

    // Optionally run a function when a day is clicked
    if (this.onDayClick) {
      this.onDayClick();
    }

    if (this.end) {
      if (this.timezoneFirstDay) {
        bcl.c.fastbooking.props.redirect = true;
      } else {
        bcl.c.fastbooking.props.redirect = false;
      }

      // Optionally run a function when a range is selected
      if (this.onSelectRange) {
        this.onSelectRange();
      }
    }
  },

  goToNextMonth: function goToNextMonth(e) {
    if (bcl.u.containsClass(bcl.c.fastbooking.calendar.props.$wrapper, bcl.c.fastbooking.calendar.props.classLoading)) {
      return;
    }

    bcl.c.fastbooking.calendar.goToNextMonth();

    // Go to the next month
    const thisMonth = e.target.getAttribute("month");
    const isMonth2 = thisMonth > 1;
    let nextMonth = isMonth2 ? this.month2 : this.month1;
    const nextDate = new Date(nextMonth);
    const maxDate = new Date();
    maxDate.setDate(new Date().getDate() + 1095);
    const isMaxDate = maxDate.getFullYear() <= nextDate.getFullYear() && maxDate.getMonth() <= nextDate.getMonth();

    nextMonth = this.getNextMonth(nextMonth);

    // Dont't go to the next month if:
    // 1. The second month is visible and it is the next month after
    //    our current month
    // 2. The month is after the (optional) endDate. There's no need
    //    to show other months in this case.
    // 3. nextMonth is bigger than 1095 days from today
    if ((!this.isSingleMonth() && !isMonth2 && this.compareMonth(nextMonth, this.month2) >= 0) || this.isMonthOutOfRange(nextMonth) || isMaxDate) {
      return;
    }

    // We can now show the month and proceed
    if (this.moveBothMonths && isMonth2) {
      this.showMonth(this.month2, 1);
    }
    this.showMonth(nextMonth, thisMonth);
    this.showSelectedDays();
    this.disableNextPrevButtons();
    // Disable next button if month after next month is greater than 1095 days from today
    if (maxDate.getFullYear() <= nextDate.getFullYear() && maxDate.getMonth() <= nextDate.getMonth() + 1) {
      e.target.classList.add("datepicker__month-button--disabled");
    }
  },
};

export default datePicker;
